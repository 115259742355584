@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
#root > div {
  height: 100%;
}

.box-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
